/* General Body Styles */
body {
  font-family: 'Inter', sans-serif; /* Clean, modern font similar to Claude.ai */
  background-color: #ffffff; /* White background for a clean look */
  margin: 0;
  padding: 0;
  color: #1a1a1a; /* Dark gray text for readability */
  line-height: 1.6;
}

/* App Container Styles */
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 40px;
}

/* General Form Styling */
form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: #ffffff;
  padding: 32px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

input[type="name"],
input[type="email"],
input[type="password"],
select {
  background: #ffffff;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 14px;
}

input:focus,
select:focus {
  border-color: #5850ec;
  outline: none;
  box-shadow: 0 0 0 2px rgba(88, 80, 236, 0.2);
}

button {
  background-color: #5850ec;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
  margin-top: 20px;
}

button:hover {
  background-color: #4c46e8;
}

/* Camera Capture Styles */
video {
  width: 100%;
  border: 1px solid #d1d5db;
  border-radius: 8px;
}

/* Messages and Errors */
p {
  color: #6b7280;
  font-weight: 400;
}

/* Navigation and instructions */
h2 {
  font-weight: 600;
  color: white;
  margin-bottom: 16px;
}

/* Image Thumbnails */
img {
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin: 8px;
  transition: transform 0.2s ease;
}

img:hover {
  transform: scale(1.05);
}

/* Responsive settings */
@media (max-width: 768px) {
  form, video {
    width: 100%;
  }
}

/* Camera Capture Styles */
.camera-capture {
  background-color: #ffffff;
  color: #1a1a1a;
  text-align: center;
  padding: 32px;
  border-radius: 8px;
  margin: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  overflow: hidden;
  position: relative;
}

.camera-capture h2 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
  color: #1a1a1a;
}

.camera-capture p {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 16px;
  color: #6b7280;
}

.camera-capture button {
  background-color: #5850ec;
  color: #ffffff;
  font-size: 14px;
  padding: 10px 16px;
  border-radius: 4px;
  font-weight: 500;
}

.camera-capture button:hover {
  background-color: #4c46e8;
}

.camera-capture video {
  border: 1px solid #d1d5db;
}

.camera-capture img {
  width: 80px;
  height: 80px;
  margin: 8px;
  border-radius: 4px;
  border: 1px solid #d1d5db;
  object-fit: cover;
}

.camera-viewer {
  width: 100%;
  max-height: 450px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

video, .image-preview {
  width: 100%;
  height: auto;
  max-height: 450px;
}

.slider-container {
  width: calc(100% - 80px);
  margin: 0 auto;
  padding: 0 40px;
  position: relative;
}

.slick-slide img {
  max-width: 80px;
  height: auto;
  margin: 0 4px;
}

.slick-prev, .slick-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 25;
  width: 32px;
  height: 32px;
  background: #f3f4f6;
  color: #1a1a1a;
  border-radius: 16px;
  border: none;
  cursor: pointer;
}

.slick-prev {
  left: -40px;
}

.slick-next {
  right: -40px;
}

.slick-prev:hover, .slick-next:hover {
  background: #e5e7eb;
}